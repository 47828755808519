<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="authUserData.user_id !== $route.params.userId"
      :account="user"
    />
    <b-card>
      <CampaignToolbar
        v-if="Object.keys(campaign).length !== 0"
        :campaign="campaign"
        :selected-banners="computedSelected"
        closing
        @uploadBanners="uploadBannersImg"
        @deleteBanner="deleteBannersImg"
        @toggleStatusBanners="toggleStatusBannersImg"
      />
      <b-progress
        v-if="progress"
        animated
        :max="100"
        :variant="progressVariant"
        :class="`mt-1 progress-bar-${progressVariant}`"
      >
        <b-progress-bar
          :value="progress"
          :variant="progressVariant"
          striped
        >
          {{ progressStep }} / {{ progressTotal }}
        </b-progress-bar>
      </b-progress>
    </b-card>
    <template v-if="campaign.type !== 'criteo' && campaign.type !== 'cpcfeed' && campaign.type !== 'adsense'">
      <b-card no-body>
        <b-card-header class="d-flex justify-content-between flex-wrap">
          <div>
            <b-form-select
              id="filterBySelect"
              v-model="filterBy"
              :options="filterOptions"
            >
              <template #first>
                <option value="">
                  {{ $t('users.all') }}
                </option>
              </template>
            </b-form-select>
          </div>

          <div class="col-picker">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range', maxDate: 'today', dateFormat: 'Y-m-d'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-close="changeRange"
              />
            </div>
          </div>
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                ref="filterInput"
                v-model.lazy="filterText"
                type="search"
                :placeholder="$t('form.search')"
                @keyup.enter="filterHandler"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  :disabled="!filterText"
                  @click="filterHandler"
                >
                  {{ $t('form.search') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card-header>
        <div
          v-if="loading"
          class="d-flex justify-content-center mb-1"
        >
          <b-spinner label="Loading..." />
        </div>
        <b-row
          v-if="Object.keys(bannersFiltred).length !== 0 && !loading"
        >
          <b-col
            :style="{opacity: opacityLoading ? .5 : 1}"
            cols="12"
          >
            <b-table
              id="creative_table"
              ref="selectableTable"
              striped
              responsive
              sort-by="status"
              :per-page="perPage"
              :current-page="currentPage"
              :items="bannersFiltred"
              :fields="filterFields"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #head(check)>
                <b-form-checkbox
                  v-model="selectAll"
                  @change="toggleSelected"
                />
              </template>
              <template #cell(check)="data">
                <b-form-checkbox
                  v-model="selectedBanners"
                  :value="data.item._id"
                />
              </template>
              <template #cell(status)="data">
                <b-badge :variant="data.item.is_moderate ? status['is_moderate'] : status[data.value]">
                  {{ data.item.is_moderate ? $t('campaigns.moderated') : $t(`selects.statuses.${data.value}`) }}
                </b-badge>
              </template>
              <template #cell(preview)="data">
                <b-img
                  :src="`${$creativeImgUrl}/${data.item.image_id}.jpg`"
                  fluid
                  :alt="data.item.title"
                />
                <small class="item-title">
                  {{ data.item.title || '' }}
                </small>
              </template>
              <template #cell(impressions)="data">
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.today') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].today">
                      {{ bannerListStats[data.item._id].today.show }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.yesterday') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].yesterday">
                      {{ bannerListStats[data.item._id].yesterday.show }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div>
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.for_period') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].show">
                      {{ bannerListStats[data.item._id].show }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
              </template>
              <template #cell(conversions)="data">
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.today') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].today">
                      {{ bannerListStats[data.item._id].today.pixel }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.yesterday') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].yesterday">
                      {{ bannerListStats[data.item._id].yesterday.pixel }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div>
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.for_period') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].pixel">
                      {{ bannerListStats[data.item._id].pixel }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
              </template>
              <template #cell(clicks)="data">
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.today') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].today">
                      {{ bannerListStats[data.item._id].today.click }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.yesterday') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].yesterday">
                      {{ bannerListStats[data.item._id].yesterday.click }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div>
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.for_period') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].click">
                      {{ bannerListStats[data.item._id].click }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
              </template>
              <template
                #cell(spent)="data"
              >
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.today') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].today && bannerListStats[data.item._id].today.amount">
                      {{ currency(user.currency, bannerListStats[data.item._id].today.amount) }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div class="mb-1">
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.yesterday') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].yesterday && bannerListStats[data.item._id].yesterday.amount">
                      {{ currency(user.currency, bannerListStats[data.item._id].yesterday.amount) }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
                <div>
                  <small>
                    <div class="text-secondary">
                      {{ $t('stats.for_period') }}
                    </div>
                    <span v-if="bannerListStats[data.item._id] && bannerListStats[data.item._id].amount">
                      {{ currency(user.currency, bannerListStats[data.item._id].amount) }}
                    </span>
                    <span v-else>0</span>
                  </small>
                </div>
              </template>
              <template #cell(total_ctr)="data">
                <small>{{ new Intl.NumberFormat('en-GB', { style: 'decimal', maximumSignificantDigits: 2 }).format(data.item.total_ctr) }}%</small>
              </template>
              <template #cell(actions)="data">
                <b-nav vertical>
                  <b-nav-item
                    class="d-flex"
                    :to="'/users/'+ $route.params.userId + '/campaigns/'+ $route.params.id + '/creatives/' + data.item._id + '/edit'"
                  >
                    <span class="mr-1">
                      <feather-icon
                        icon="Edit2Icon"
                        size="16"
                      />
                    </span>
                    <span>{{ $t('settings') }}</span>
                  </b-nav-item>
                  <b-nav-item
                    class="d-flex"
                    @click="toggleStatusBannerImg(data.item)"
                  >
                    <span class="mr-1">
                      <feather-icon
                        :icon="data.item.status == 'inactive' ? 'PlayIcon' : 'PauseIcon'"
                        size="16"
                      />
                    </span>
                    <span>{{ data.item.status == 'inactive' ? $t(`form.turnon`) : $t(`form.turnoff`) }}</span>
                  </b-nav-item>
                  <b-nav-item
                    class="d-flex"
                    @click="copyBanner(data.item._id)"
                  >
                    <span class="mr-1">
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                      />
                    </span>
                    <span>{{ $t('form.copy') }}</span>
                  </b-nav-item>

                  <b-nav-item
                    class="d-flex"
                    :to="'/users/'+ $route.params.userId + '/campaigns/'+ $route.params.id + '/creatives/' + data.item._id + '/stats'"
                  >
                    <span class="mr-1">
                      <feather-icon
                        icon="BarChart2Icon"
                        size="16"
                      />
                    </span>
                    <span>{{ $t('Statistics') }}</span>
                  </b-nav-item>

                  <b-nav-item
                    class="d-flex"
                    @click="deleteBannerImg(data.item._id)"
                  >
                    <span class="mr-1 text-danger">
                      <feather-icon
                        icon="Trash2Icon"
                        size="16"
                      />
                    </span>
                    <span class="text-danger">{{ $t('form.delete') }}</span>
                  </b-nav-item>
                </b-nav>
              </template>
              <template #foot(check)="data">
                {{ data.emptyText }}
              </template>
              <template #row-details="row">
                <small>
                  ID:
                  <span
                    class="copy-link"
                    @click="doCopy(row.item._id)"
                  >
                    {{ row.item._id }}
                  </span>
                </small>
                <feather-icon
                  class="ml-25"
                  icon="ClipboardIcon"
                  size="14"
                />
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div
          v-else
          class="card-nodata"
        >
          <div class="card-body">
            {{ $t('banners.nodata_filter') }}
          </div>
        </div>
        <b-card-footer class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
              v-model="perPage"
              :options="pageOptions"
              class="mx-1"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ totalRows }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
            />
          </div>
        </b-card-footer>
      </b-card>
    </template>
  </div>
</template>
<script>
import { getUserData } from '@/auth/utils'
import getFormattedDate from '@/utils/date-formatter'
import currencyFormatter from '@/utils/currency-formatter'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import {
  BSpinner,
  BTable,
  BCard,
  BCardHeader,
  BCardFooter,
  BImg,
  BButton,
  BRow,
  BCol,
  BBadge,
  BNav,
  BNavItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  VBTooltip,
  BProgress,
  BProgressBar,
  BPagination,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import CampaignToolbar from '@/views/dashboard/campaigns/CampaignToolbar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import wait from '@/utils/wait'

const currentDate = new Date()
const defaultDateFrom = getFormattedDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
const defaultDateTo = getFormattedDate(currentDate)

export default {
  components: {
    flatPickr,
    BSpinner,
    Breadcrumbs,
    UserToolbar,
    BCard,
    BButton,
    BTable,
    BNav,
    BNavItem,
    BPagination,
    BCardHeader,
    BCardFooter,
    BImg,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    CampaignToolbar,
    BProgress,
    BProgressBar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      getFormattedDate,
      currency: currencyFormatter,
      loading: false,
      opacityLoading: false,
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      user: {},
      campaign: {},
      banners: [],
      bannerListStats: {},
      selectedBanners: [],
      authUserData: getUserData(),
      progress: 0,
      progressStep: 0,
      progressTotal: 0,
      progressVariant: 'success',
      newBanners: [],
      fields: [
        {
          key: 'check',
          label: 'check',
          class: 'cell-check text-center',
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          class: 'cell-status',
        },
        { key: 'preview', label: 'Preview', class: 'cell-preview' },
        {
          key: 'impressions',
          label: 'impressions',
          class: 'cell-shows text-center',
        },
        {
          key: 'conversions',
          label: 'conversions',
          class: 'cell-conversions text-center',
        },
        {
          key: 'clicks',
          label: 'clicks',
          class: 'cell-clicks text-center',
        },
        {
          key: 'total_ctr',
          label: 'Ctr',
          sortable: true,
          class: 'cell-ctr',
        },
        {
          key: 'spent',
          label: 'spent',
          class: 'cell-spent text-center',
        },
        { key: 'actions', label: 'Actions', class: 'cell-actions' },
      ],
      perPage: 20,
      pageOptions: [5, 10, 20, 50],
      currentPage: 1,
      filter: null,
      filterText: null,
      filterOn: [],
      status: {
        active: 'light-success',
        inactive: 'light-danger',
        is_moderate: 'light-warning',
      },
      selectAll: false,
      filterBy: '',
      filterOptions: [
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inctive' },
        { value: 'is_moderate', text: 'Moderate' },
      ],
      rangePicker: [],
      urlParams: {
        from: defaultDateFrom,
        to: defaultDateTo,
      },
    }
  },
  computed: {
    computedSelected() {
      return this.selectedBanners.length
    },
    filtredUrlParams() {
      const urlParams = {}
      Object.keys(this.urlParams).forEach(p => {
        if (this.urlParams[p] !== '' && this.urlParams[p] !== null) {
          urlParams[p] = this.urlParams[p]
        }
      })
      return urlParams
    },
    bannersFiltred() {
      if (this.filterBy !== '') {
        if (this.filterBy === 'active' || this.filterBy === 'inactive') {
          return this.banners.filter(banner => banner.status === this.filterBy)
        }

        if (this.filterBy === 'is_moderate') {
          return this.banners.filter(banner => banner.is_moderate)
        }
      }

      return this.banners.map(b => ({ ...b, _showDetails: true }))
    },
    totalRows() {
      return this.bannersFiltred.length
    },
    filterFields() {
      let { fields } = this
      const hasUndefinedAmount = Object.keys(this.bannerListStats).some(key => this.bannerListStats[key].amount === undefined)
      if (hasUndefinedAmount) {
        fields = fields.filter(f => f.key !== 'spent')
      }
      return fields
    },
  },
  watch: {
    urlParams: {
      handler(newVal) {
        this.urlParams = Object.assign(this.urlParams, newVal)
        this.$router.replace({
          name: 'dashboard-campaigns-creatives-table',
          query: this.filtredUrlParams,
          params: { savePosition: true },
        }).catch(() => {})
        this.opacityLoading = true
        setTimeout(async () => {
          await this.getBannerListStats(this.filtredUrlParams)
          this.opacityLoading = false
        }, 500)
      },
      deep: true,
    },
    perPage(newVal) {
      localStorage.setItem('perPage', newVal)
    },
    filterText(newVal) {
      if (newVal === '') {
        this.filter = newVal
      }
    },
  },
  async created() {
    const perPage = localStorage.getItem('perPage')
    if (perPage) {
      this.perPage = parseInt(perPage, 10)
    }
    this.getUser(this.$route.params.userId)

    await this.getBannerListStats(this.urlParams)
  },
  methods: {
    async getStats() {
      const paramsFromUrl = this.getParamsFromUrl()
      if (Object.keys(paramsFromUrl).length !== 0) {
        if (paramsFromUrl.to === undefined) {
          paramsFromUrl.to = paramsFromUrl.from
        }
        this.urlParams = Object.assign(this.urlParams, paramsFromUrl)
        this.rangePicker = [paramsFromUrl.from, paramsFromUrl.to]
      } else {
        this.$router.replace({
          name: 'dashboard-campaigns-creatives-table',
          query: this.urlParams,
          params: { savePosition: true },
        }).catch(() => {})
        await this.getBannerListStats(this.urlParams)
      }
    },
    getParamsFromUrl() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const urlParams = Object.fromEntries(urlSearchParams.entries())
      const paramsFromUrl = {}
      Object.keys(urlParams).forEach(p => {
        if (this.urlParams[p]) {
          paramsFromUrl[p] = urlParams[p]
        }
      })
      return paramsFromUrl
    },
    async changeRange(range) {
      let dateFrom
      let dateTo
      const selectedRange = range
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      if (dateTo === undefined) {
        dateTo = dateFrom
      }

      this.urlParams = Object.assign(this.urlParams, { from: getFormattedDate(dateFrom), to: getFormattedDate(dateTo) })
    },
    filterHandler() {
      this.filter = this.filterText
    },
    async getBanners() {
      this.loading = true
      const params = { campaign_id: this.$route.params.id }
      const dataResponse = await useJwt.getCampaignBanners(params)
      this.banners = dataResponse.data.banners || []
      await this.getStats()
      this.loading = false
      this.setBreabcrumbs()
    },
    async getBannerListStats(params) {
      this.rangePicker = [params.from, params.to]
      const dataResponse = await useJwt.getCampaignListStats({ ...params, campaign_id: this.$route.params.id })
      this.bannerListStats = dataResponse.data.banners || []
    },
    async getCampaignData() {
      const responseData = await useJwt.getCampaign(this.$route.params.id)
      this.campaign = responseData.data.campaign || {}
      this.getBanners()
    },
    async getUser(id) {
      const responseData = await useJwt.getUser(id)
      this.user = responseData.data.account || {}
      this.pageTitle = this.user.name
      this.pageSubtitle = this.user.email
      this.getCampaignData()
    },
    uploadBannersImg(files) {
      const formData = new FormData()
      const fileList = files
      fileList.map(f => formData.append('files', f, f.name))
      useJwt.uploadBannersImg(this.$route.params.id, formData)
        .then(wait(1500))
        .then(response => {
          const { banners } = response.data
          if (banners.length === 1) {
            this.$router.push(`/users/${this.$route.params.userId}/campaigns/${this.$route.params.id}/creatives/${banners[0]}/create`)
          } else {
            this.getBanners()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
          document.querySelector('.form-control-file').value = ''
        })
    },
    async copyBanner(id) {
      await useJwt.copyBannerImg(id)
      this.getBanners()
    },
    deleteBannerImg(id) {
      this.$swal({
        title: 'Want to delete creative?',
        text: 'After that creative could not be restored',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteBannerImg(id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.getBanners()
            }
          })
        }
      })
    },
    async deleteBannersImg() {
      await this.$swal({
        title: 'Are you sure you want to delete the selected creatives?',
        text: 'After that creative could not be restored',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('swal.successfullydeleted'),
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          const totalBanners = this.selectedBanners.length
          this.progressTotal = totalBanners
          const stepWidth = 100 / totalBanners
          this.progressVariant = 'danger'
          this.selectedBanners.forEach(c => {
            useJwt.deleteBannerImg(c).then(response => {
              if (response.status === 204) {
                this.progressStep += 1
                this.progress += stepWidth
              }
            })
          })
          setTimeout(() => {
            this.progress = 0
            this.progressStep = 0
            this.progressTotal = 0
            this.progressVariant = 'success'
            this.selectedBanners = []
            this.getBanners()
          }, 1000)
        }
      })
    },
    async toggleStatusBannerImg(banner) {
      if (!banner.is_moderate) {
        if (banner.status === 'inactive') {
          banner.status = 'active'
          // new Date(date).toISOString()
          if (banner.targetings.date.params.startdate !== '') {
            banner.targetings.date.params.startdate = new Date(banner.targetings.date.params.startdate).toISOString()
          }

          if (banner.targetings.date.params.enddate !== '') {
            banner.targetings.date.params.enddate = new Date(banner.targetings.date.params.enddate).toISOString()
          }

          if (banner.targetings.date_num !== undefined && banner.targetings.date_num.params.dates.length > 0) {
            banner.targetings.date_num.params.dates = banner.targetings.date_num.params.dates.map(d => new Date(d).toISOString())
          }

          await useJwt.setBanner(banner._id, banner)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Activation!',
                text: 'Successfully activated.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              banner.status = 'inactive'
              if (error.response && error.response.status === 422) {
                if (error.response.data.errors.length) {
                  error.response.data.errors.forEach(err => {
                    const field = err.source.pointer.split('/')
                    this.$store.dispatch(
                      'error-store/showNotify',
                      {
                        title: `Error [${field[field.length - 1]}]`,
                        msg: err.detail,
                        variant: 'danger',
                      },
                      { root: true },
                    )
                  })
                }
              }
              if (error.response && error.response.status === 500) {
                this.$store.dispatch(
                  'error-store/showNotify',
                  {
                    title: error.response.statusText,
                    msg: error.message,
                    variant: 'danger',
                  },
                  { root: true },
                )
              }
            })
        } else {
          this.$swal({
            title: 'Confirmation',
            text: 'Will be inactive',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.value) {
              banner.status = 'inactive'
              // new Date(date).toISOString()
              if (banner.targetings.date.params.startdate !== '') {
                banner.targetings.date.params.startdate = new Date(banner.targetings.date.params.startdate).toISOString()
              }
              if (banner.targetings.date.params.enddate !== '') {
                banner.targetings.date.params.enddate = new Date(banner.targetings.date.params.enddate).toISOString()
              }
              if (banner.targetings.date_num !== undefined && banner.targetings.date_num.params.dates.length > 0) {
                banner.targetings.date_num.params.dates = banner.targetings.date_num.params.dates.map(d => new Date(d).toISOString())
              }
              await useJwt.setBanner(banner._id, banner)
                .then(() => {
                  this.$swal({
                    icon: 'success',
                    title: 'Deactivation!',
                    text: 'Successfully deactivated',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                })
                .catch(error => {
                  if (error.response && error.response.status === 422) {
                    if (error.response.data.errors.length) {
                      error.response.data.errors.forEach(err => {
                        const field = err.source.pointer.split('/')
                        this.$store.dispatch(
                          'error-store/showNotify',
                          {
                            title: `Error [${field[field.length - 1]}]`,
                            msg: err.detail,
                            variant: 'danger',
                          },
                          { root: true },
                        )
                      })
                    }
                  }
                  if (error.response && error.response.status === 500) {
                    this.$store.dispatch(
                      'error-store/showNotify',
                      {
                        title: error.response.statusText,
                        msg: error.message,
                        variant: 'danger',
                      },
                      { root: true },
                    )
                  }
                })
            } else if (result.dismiss === 'cancel') {
              banner.status = 'active'
              this.$swal({
                title: 'Cancelled',
                text: 'Status remain active',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      }
    },
    toggleStatusBannersImg(status) {
      const totalBanners = this.selectedBanners.length
      this.progressTotal = totalBanners
      const stepWidth = 100 / totalBanners
      this.progressVariant = status === 'inactive' ? 'secondary' : 'success'
      this.selectedBanners.forEach(sb => {
        const bannerData = this.banners.find(b => b._id === sb)
        const currentBanner = { ...bannerData }

        if (currentBanner.targetings.date.params.startdate !== '') {
          currentBanner.targetings.date.params.startdate = new Date(currentBanner.targetings.date.params.startdate).toISOString()
        }

        if (currentBanner.targetings.date.params.enddate !== '') {
          currentBanner.targetings.date.params.enddate = new Date(currentBanner.targetings.date.params.enddate).toISOString()
        }

        if (currentBanner.targetings.date_num !== undefined && currentBanner.targetings.date_num.params.dates.length > 0) {
          currentBanner.targetings.date_num.params.dates = currentBanner.targetings.date_num.params.dates.map(d => new Date(d).toISOString())
        }

        if (!currentBanner.is_moderate) {
          currentBanner.status = status
          useJwt.setBanner(sb, currentBanner).then(response => {
            if (response.status === 204) {
              this.progressStep += 1
              this.progress += stepWidth
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.$t('notifications.creative.name')} "${currentBanner.title}" ${this.$t('campaigns.moderated').toLowerCase()}`,
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
        }
      })
      setTimeout(() => {
        this.progressVariant = 'success'
        this.progress = 0
        this.progressStep = 0
        this.progressTotal = 0
        this.selectedBanners = []
        this.getBanners()
      }, 1000)
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: 'users',
          to: '/users',
        },
        {
          text: this.user.name,
          to: `/users/${this.$route.params.userId}`,
        },
        {
          text: 'campaigns',
          to: `/users/${this.$route.params.userId}/campaigns`,
        },
        {
          text: this.campaign.title,
          active: true,
        },
      ]
    },
    // table
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    toggleSelected(event) {
      if (event) {
        this.selectedBanners = []
        this.bannersFiltred.forEach(item => {
          this.selectedBanners.push(item._id)
        })
      } else {
        this.selectedBanners = []
      }
    },
    doCopy(id) {
      this.$copyText(id).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('banners.creative_id_copied'),
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('banners.creative_id_copy_error'),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
[dir] .flatpickr-input[readonly] {
  min-width: 200px;
}

.card .card-header {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.banner-item .card-header h5 {
  font-size: 12px;
}

.feather-trash-2 {
  margin-top: -3px;
}
.banner-img {
  position: relative;
}

.banner-img .badge {
  position: absolute;
  left: 15px;
  top: 15px;
}

.banner-img .btn-status {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.banner-img label {
  margin: 0;
  padding: 0;
  display: block;
}

.banner-img .img-fluid {
  width: 100%;
}

.btn.is_moderate:hover {
  cursor: default;
  box-shadow: none;
  color: #fff;
  border-color: #ff9f43 !important;
  background-color: #ff9f43 !important;
}

</style>
